<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          LISTES DES BASES
        </v-toolbar-title>
        <v-spacer></v-spacer>
        
        <v-btn icon>
          <v-icon @click="closeModal">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-progress-circular v-if="loader" class="loader" :size="70" :width="10" color="purple"
        indeterminate></v-progress-circular> -->
      <div class="d-flex justify-space-around">
        <div v-if="isDirectionRight" class="d-flex flex-column pa-2"
          :style="{ display: !isDirectionRight ? 'none' : 'block', width: '100%' }">
 
          <h2 v-if="baseToTcheckId" class="mx-2 mt-4 mb-2">En attente de validation de la direction</h2>
          <h2 v-else class="mx-2 mt-4 mb-2">Liste des demandes</h2>
 
          <v-card-title>
            <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Search" single-line>
            </v-text-field>
          </v-card-title>
          <v-data-table 
            :headers="headers" 
            :items="baseToTcheckId ? sortByBaseToTcheck : filteredBaseList" 
            :search="search" 
            :items-per-page="10"
            class="elevation-1"
            :loading="loader"
            :footer-props="{
              'items-per-page-text': $t('doubleAuth.rows-per-page'),
              'items-per-page-all-text': $t('doubleAuth.all'),
            }"
          >
            <template v-slot:item="{ item }">
              <tr :class="{ 'highlight-row': isBaseToTcheck && item._id === baseToTcheckId }">
                <td>{{ item.client.toString() }}</td>
                <td>{{ item.requestedURLs }}</td>
                <td>{{ item.requestDate | formatDate }}</td>
                <td>
                  <span v-if="!item.validationDirectionDate" @click="dialogValidateDirection = true" style="cursor: pointer">
                    <v-icon 
                    title="Cliquer pour valider la demande" 
                    color="warning"
                  >
                    {{ item.validationDirectionDate ? 'mdi-calendar-check' : 'mdi-calendar-edit'}}
                  </v-icon>
                    Valider la demande
                  </span>
                  <!-- dialog pour valider la demande -->
                  <v-dialog v-model="dialogValidateDirection" max-width="30%">
                    <v-card>
                      <v-card-title>
                        <span>Validation de la demande</span>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea v-model="item.validationDirectionRemarks" label="Remarque" outlined></v-textarea>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialogValidateDirection = false">
                          Annuler
                        </v-btn>
                        <v-btn text color="success" @click="validateBase(item)">
                          Enregistrer
                        </v-btn>
                        <span v-if="item.validationDirectionDate">
                          <v-icon title="Date de validation de la direction" color="success">mdi-calendar-check</v-icon>
                          {{ item.validateBaseDate | formatDate }}
                        </span>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- <v-btn text color="success" @click="validateBase(item)">
                    Enregistrer
                  </v-btn> -->
                  <span v-if="item.validationDirectionDate">
                    <v-icon title="Date de validation de la direction" color="success">mdi-calendar-check</v-icon>
                    {{ item.validationDirectionDate | formatDate }}
                  </span>
                </td>
                <!-- refuser la demande -->
                <td>
                  <span style="cursor: pointer" v-if="!item.validationDirectionDate" @click="dialogInvalidate = true">
                    <v-icon 
                      :title="item.validationStatusDate && item.validationStatusDate ? 'Cliquer pour ajouter une remarque' : 'Cliquer pour refuser la demande'"
                      color="error"
                      >mdi-calendar-remove</v-icon>
                    {{ item.validationStatusDate && item.validationStatusDate ? 'Refus le' : 'Refuser la demande' }}
                    {{ item.validationStatusDate && item.validationStatusDate | formatDate }}
                  </span> 
                    <span v-else-if="item.validationDirectionDate">
                      <v-icon 
                       color="error">mdi-calendar-remove</v-icon>
                       Base déjà traitée
                    </span>
                  <!-- dialog saisi de remarque si refus -->
                  <v-dialog v-model="dialogInvalidate" max-width="30%">
                    <v-card>
                      <v-card-title>
                        <span>Remarque</span>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea v-model="item.invalidationRemarks" label="Remarque" outlined></v-textarea>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialogInvalidate = false">
                          Annuler
                        </v-btn>
                        <v-btn text color="success" @click="invalidateBase(item)">
                          Enregistrer
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog> 
                </td>
                <td>
                  <v-icon size="20" class="mr-2" @click="openDetailsModal(item._id)">mdi-eye</v-icon>
                  <v-icon small class="mr-2" @click="openUpdateModal(item._id)">mdi-pencil</v-icon>
                  <v-icon small @click="openAlertDeleteBase(item._id)">mdi-delete</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
    <!-- modale update de bases -->
    <v-dialog v-model="dialogUpdate" max-width="90%">
      <basesUpdate @close-modal="closeModal" />
    </v-dialog>
    <!-- modale details de bases -->
    <v-dialog v-model="dialogDetails" max-width="90%">
      <basesDetails @close-modal="closeDetailsModal" />
    </v-dialog>
    <!-- modale de suppression de bases -->
    <v-dialog v-model="alertDelete" max-width="25%">
      <alertDeleteBase @base-deleted="refreshBasesList" @close-modal="closeAlertDeleteBase" />
    </v-dialog>
  </div>
</template>

<script>
import BasesService from '@/Services/SupportVision/BasesService';
import moment from 'moment';
import { mapMutations, mapState } from 'vuex';
import basesUpdate from './basesUpdate.vue';
import basesDetails from './details.vue';
import alertDeleteBase from './alertDeleteBase.vue';

export default {
  name: 'basesListToTcheck',
  components: {
    basesUpdate,
    basesDetails,
    alertDeleteBase,
  },
  data: () => ({
    dialogUpdate: false,
    dialogDetails: false,
    dialogInvalidate: false,
    dialogValidateDirection: false,
    alertDelete: false,
    loader: false,
    isCheckboxChecked: false,
    isDirectionRight: true,
    menuDate: false,
    menuInvalidate: false,
    isSysAdminRight: true,
    on: {},
    base: {
      isOpenedToOutside: false,
    },
    headers: [
      { text: 'CLIENT', value: 'client' },
      { text: 'URL', value: 'requestedURLs' },
      { text: 'DATE DE REQUETE', value: 'requestDate' },
      { text: 'VALIDER LA DEMANDE', value: 'validation', sortable: false },
      { text: 'REFUSER LA DEMANDE', value: 'invalidation', sortable: false },
      { text: 'ACTIONS', value: 'actions', sortable: false },
    ],
    basesDetails: [],
    search: '',
  }),
  filters: {
    formatDate(date) {
      if (date !== null && date !== undefined) {
        return moment(date).format('DD/MM/YYYY');
      }
    },
  },
  computed: {
    ...mapState(['baseList', 'clientList', 'baseToTcheckId']),
    // verfi si la base est validée
    isValidateBase() {
      return this.baseList.data.isValidateBase;
    },
    filteredBaseList() {
      return this.baseList.data;
    },
    // techek l id de la base et celui de la base a tcheck si ils sont identiques pour lui donner une couleur differente
    isBaseToTcheck() {
      return this.baseList.data.some(base => base._id === this.baseToTcheckId);
    },
    sortByBaseToTcheck() {
      // afficher que la base a tcheck seule
      return this.baseList.data.filter(base => base._id === this.baseToTcheckId); 
    },
  },
  methods: {
    ...mapMutations(['setSelectedClientId', 'setBaseToTcheckId']),
    closeModal() {
      this.dialogUpdate = false;
      // si annulation on remet les datee telle qu'elles étaient
      this.$emit('refresh-bases-list')
      this.$emit('close-modal');
      this.$store.commit('setBaseToTcheckId', null);
    },
    closeDetailsModal() {
      this.dialogDetails = false;
    },
    async openUpdateModal(baseId) {
      this.setSelectedClientId(baseId);
      this.dialogUpdate = true;
    },
    async openDetailsModal(baseId) {
      this.setSelectedClientId(baseId);
      this.$store.commit('setBaseToTcheckId', baseId);
      // // afficher que la base selectionnée
      // this.baseList.data = this.baseList.data.filter(base => base._id === baseId);
      this.dialogDetails = true;
    },
    closeAlertDeleteBase() {
      this.alertDelete = false;
    },
    checkboxChanged() {
      this.isCheckboxChecked = this.baseList.data.some(base => base.isValidateBase && base.isSysAdminValidate);
    },
    async invalidateBase(item) {
      try {
        item.isValidateBase = false;
        for (const base of this.baseList.data) {
          const baseId = base._id;
          if (!base.isValidateBase && baseId === this.baseToTcheckId) {
            base.step2 = false;
            base.dataKey = 'isInvalidateBase'
            await BasesService.updateBase(baseId, {step: 'step2', dataKey: base.dataKey, baseId: baseId, invalidationRemarks: item.invalidationRemarks});
          }
        }
        await this.refreshBasesList();
        this.$store.commit('setBaseToTcheckId', null);
        this.closeModal();
      } catch (error) {
        console.error('Erreur lors de la validation de la base :', error);
      }
    },
    async validateBase(item) {
      try {
        item.isValidateBase = true;
        for (const base of this.baseList.data) {
          const baseId = base._id;
          if (base.isValidateBase && baseId === this.baseToTcheckId) {
            base.step2 = true;
            base.dataKey = 'isValidateBase'
            base.validationDirectionRemarks ? base.validationDirectionRemarks : 'Aucune remarque';
            await BasesService.updateBase(baseId, {step: 'step2', dataKey: base.dataKey, baseId: baseId, validationDirectionRemarks: item.validationDirectionRemarks});
          }
        }
        await this.refreshBasesList();
        this.$store.commit('setBaseToTcheckId', null);
        this.closeModal();
      } catch (error) {
        console.error('Erreur lors de la validation de la base :', error);
      }
    }, 
    openAlertDeleteBase(baseId) {
      this.setSelectedClientId(baseId);
      this.alertDelete = true;
    },
    async refreshBasesList() {
      const bases = await BasesService.getBasesList();
      this.$store.commit('setBaseList', bases);
    },
  },
  async mounted() {
    this.loader = true;
    try {
      await this.refreshBasesList();
    } catch (error) {
      console.error(error);
    } finally {
      this.loader = false;
    }
  },
};
</script>
<style scoped>
.divider-stroke {
  border-width: 1px;
}

span {
  word-break: break-word;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.calendar-scale:hover {
  transform: scale(1.2);
}

.highlight-row {
  font-weight: bold;
  color: #151515;
  border: #cccccc 1px solid;
  box-shadow: 0 0 10px 3px #ccc inset;
  
}
</style>


