<template>
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          LISTES DES BASES
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="closeModal">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <h2 v-if="baseToTcheckId" class="mx-4 mt-4 mb-2">En attente de validation du sysadmin</h2>
      <h2 v-else class="mx-4 mt-4 mb-2">Liste des demandes</h2>

      <!-- <v-progress-circular v-if="loader" class="loader" :size="70" :width="10" color="purple"
        indeterminate>
      </v-progress-circular> -->

      <v-card-title class="mt-4">
        <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Search" single-line>
        </v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="baseToTcheckId ? sortByBaseToTcheck : filteredBaseListSysAdmin"
        :items-per-page="10"
        class="elevation-1"
        :search="search"
        sort-by="requestDate"
        sort-desc
        dense
        :loading="loader"
        :footer-props="{
          'items-per-page-text': $t('doubleAuth.rows-per-page'),
          'items-per-page-all-text': $t('doubleAuth.all'),
        }"
      >
      <template v-slot:item="{ item }">
          <tr :class="{ 'highlight-row': isBaseToTcheck && item._id === baseToTcheckId }">
            <td>{{ item.client.toString() }}</td>
            <td>{{ item.requestedURLs }}</td>
            <td>
              <span class="d-flex align-center">
                <v-icon title="Date de la requête" color="primary">mdi-calendar-import</v-icon>
                {{ item.requestDate | formatDate }}
              </span>
            </td>
            <td>  
              <div class="d-flex" v-if="!item.isSysAdminValidate">
                <v-checkbox v-model="item.isSysAdminValidate" @change="checkboxChangedAdmin(item._id)" dense></v-checkbox>
                <v-checkbox v-if="item.isFailed && !item.step4" 
                  label="Valider test KO" 
                  dense 
                  @change="checkboxChangedValidationNeeded(item._id)"
                ></v-checkbox>
              </div>
              <div v-else>
                <span>
                  <v-icon title="Date de validation" color="primary">mdi-calendar-check</v-icon>
                  {{ item.sysAdminValidationDate | formatDate }}</span>
              </div>
            </td> 
            <td>
              <div class="d-flex" v-if="!item.isOpenedToOutside">
                <v-checkbox v-model="item.isOpenedToOutside" @change="checkboxChangedOpenedToOutside(item._id)" dense></v-checkbox>
              </div>
              <div v-else>
                <span>
                  <v-icon title="Date d'ouverture" color="success">mdi-calendar-check</v-icon>
                  {{ item.openedToOutsideDate | formatDate }}</span>
              </div>
            </td>
            <td class="text-center">
              <v-icon size="20" class="mr-2" @click="openDetailsModal(item._id)">mdi-eye</v-icon>
              <v-icon size="20" class="mr-2" @click="openUpdateModal(item._id)">mdi-square-edit-outline</v-icon>
              <v-icon size="20" color="red" @click="openAlertDeleteBase(item._id)">mdi-delete</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      
    </v-card>
    <!-- modale update de bases -->
    <v-dialog v-model="dialogUpdate" max-width="90%">
      <basesUpdate @close-modal="closeModal" />
    </v-dialog>
    <!-- modale details de bases -->
    <v-dialog v-model="dialogDetails" max-width="90%">
      <basesDetails @close-modal="closeDetailsModal" />
    </v-dialog>
    <!-- modale de suppression de bases -->
    <v-dialog v-model="alertDelete" max-width="25%">
      <alertDeleteBase  @base-deleted="refreshBasesList" @close-modal="closeAlertDeleteBase" />
    </v-dialog>
  </div>
</template>

<script>
import BasesService from '@/Services/SupportVision/BasesService';
import moment from 'moment';
import { mapMutations, mapState } from 'vuex';
import basesUpdate from './basesUpdate.vue';
import basesDetails from './details.vue';
import alertDeleteBase from './alertDeleteBase.vue';

export default {
  name: 'sysAdminTcheck',
  components: {
    basesUpdate,
    basesDetails,
    alertDeleteBase,
  },
  data: () => ({
    dialogUpdate: false,
    dialogDetails: false,
    alertDelete: false,
    loader: false,
    isCheckboxChecked: false,
    isDirectionRight: false,
    isSysAdminRight: true,
    on: {},
    base: {
      isOpenedToOutside: false,
    },
    headers: [
      { text: 'CLIENT', value: 'client' },
      { text: 'URL', value: 'requestedURLs' },
      { text: 'DATE DE REQUETE', value: 'requestDate' },
      { text: 'VALIDER LA BASE', value: 'isSysAdminValidate', sortable: false },
      // ouverture vers l'exterieur
      { text: 'OUVRIR VERS L\'EXTERIEUR', value: 'isOpenedToOutside', sortable: false },
      { text: 'ACTIONS', value: 'actions', sortable: false, align: 'center' },
    ],
    search: '',
    dataKey: '',
  }),
  filters: {
    formatDate(date) {
      if (date !== null && date !== undefined) {
        return moment(date).format('DD/MM/YYYY');
      }
    },
  },
  computed: {
    ...mapState(['baseList', 'clientList', 'baseToTcheckId']),
    // verfi si la base est validée
    isValidateBase() {
      return this.baseList.data.isValidateBase;
    },
    filteredBaseListSysAdmin() {
      return this.baseList.data;
    },
    isBaseToTcheck() {
      return this.baseList.data.some(base => base._id === this.baseToTcheckId && !base.isOpenedToOutside);
    },
    sortByBaseToTcheck() {
      // afficher que la base a tcheck seule
      return this.baseList.data.filter(base => base._id === this.baseToTcheckId && !base.isOpenedToOutside); 
    },
  },
  methods: {
    ...mapMutations(['setSelectedClientId', 'setBaseToTcheckId']),
    closeModal() {
      this.dialogUpdate = false;
      // si annulation on remet les datee telle qu'elles étaient
      this.$emit('refresh-bases-list')
      this.$emit('close-modal');
      this.$store.commit('setBaseToTcheckId', null);
    },
    closeDetailsModal() {
      this.dialogDetails = false;
    },
    async openUpdateModal(baseId) {
      this.setSelectedClientId(baseId);
      this.dialogUpdate = true;
    },
    async openDetailsModal(baseId) {
      this.setSelectedClientId(baseId);
      this.$store.commit('setBaseToTcheckId', baseId);
      this.dialogDetails = true;
    },
    closeAlertDeleteBase() {
      this.alertDelete = false;
    },
    async checkboxChangedAdmin(id) { 
      let baseId = null; 
      let auth = false 
      this.baseList.data.find(item => {
        if (item._id === id && item.isSysAdminValidate) {
          this.dataKey = 'isSysAdminValidate';
          for (const base of this.baseList.data) {
            baseId = base._id;
            if (base.isSysAdminValidate && baseId === id) {
              base.step3 = true;
              base.dataKey = this.dataKey;
              auth = true
            }
          }
        }
      });
      baseId = id
      this.dataKey = 'isSysAdminValidate';
      if (auth) {
        const res = await BasesService.updateBase(baseId, {step: 'step3', dataKey: this.dataKey, baseId: baseId});
      } 
      // commit dans le store
      await this.refreshBasesList();
      this.closeModal();
    },
    async checkboxChangedValidationNeeded(id) { 

      const baseId = id
      this.dataKey = 'isTested';
        const res = await BasesService.updateBase(baseId, {step: 'step4', dataKey: this.dataKey, baseId: baseId});
      // commit dans le store
      await this.refreshBasesList();
      this.closeModal();
    },
    async checkboxChangedOpenedToOutside(id) { 
      let baseId = null;
      let auth = false
      this.baseList.data.find(item => {
        if (item._id === id && item.isOpenedToOutside) {
          this.dataKey = 'isOpenedToOutside';
          for (const base of this.baseList.data) {
            baseId = base._id;
            if (base.isOpenedToOutside && baseId === id) {
              base.step5 = true;
              base.dataKey = this.dataKey;
              auth = true
            }
          }
        }
      });
      baseId = id
      this.dataKey = 'isOpenedToOutside';
      if (auth) {
        const res = await BasesService.updateBase(baseId, {step: 'step5', dataKey: this.dataKey, baseId: baseId, isActive: true });
      }
      // commit dans le store
      await this.refreshBasesList();
      this.closeModal();
    }, 
    openAlertDeleteBase(baseId) {
      this.setSelectedClientId(baseId);
      this.alertDelete = true;
    },
    async refreshBasesList() {
      const bases = await BasesService.getBasesList();
      this.$store.commit('setBaseList', bases);
    },
  },
  async mounted() {
    this.loader = true;  
    try { 
      await this.refreshBasesList();
    } catch (error) { 
      console.error(error);
    } finally {
      this.loader = false;  
    }
  },
};
</script>
<style scoped>
.divider-stroke {
  border-width: 1px;
}

span {
  word-break: break-word;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.highlight-row {
  font-weight: bold;
  color: #151515;
  border: #cccccc 1px solid;
  box-shadow: 0 0 10px 3px #ccc inset;
  
}
</style>


