<template>
  <v-card :key="modalKey">
    <v-toolbar color="primary" dark class="mb-2">
      <v-toolbar-title class="">CREATION DE BASE</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        icon
        title="Ajouter un contact"
        @click="contactDialog = true"
      >
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
      <!-- dialog -->
      <v-dialog v-model="contactDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Ajouter un contact</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" >
                  <v-text-field v-model="formData.contact.function" label="Fonction"></v-text-field>
                </v-col>
                <v-col cols="6" >
                  <v-text-field v-model="formData.contact.firstName" label="Prénom"></v-text-field>
                </v-col>
                <v-col cols="6" >
                  <v-text-field v-model="formData.contact.lastName" label="Nom"></v-text-field>
                </v-col>
                <v-col cols="6"  >
                  <v-text-field v-model="formData.contact.email" label="Email"></v-text-field>
                </v-col>
                <v-col cols="6"  >
                  <v-text-field v-model="formData.contact.phone" label="Téléphone"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="contactDialog = false, formData.contact = {}"
            >Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="addContact">Ajouter</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 
      <v-btn icon @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-progress-circular v-if="loader" class="loader" :size="100" :width="10" color="purple"
      indeterminate></v-progress-circular>
    <v-form @submit.prevent="submitForm">
      <!-- Information générale -->
      <div class="d-flex mx-2 form-container">
        <div style="width: 48%;">
          <v-card-text>
            <!-- nom de la base -->
            <v-text-field label="Nom de la base*" v-model="formData.baseName" required
            :rules="[baseNamesRule]"></v-text-field>
            <div class="d-flex form-container">
              <div style="width: 48%;">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-text-field 
                      :value="formData.requestDate | formatDate"
                      label="Date de la demande*" 
                      v-on="on"
                      required 
                      :rules="[requestDateRule]"
                    >
                      {{ formData.requestDate | formatDate }}
                    </v-text-field>
                  </template>
                  <v-date-picker style="width: 100%" 
                    v-model="formData.requestDate" 
                    :locale="$i18n.locale" 
                    color="primary"
                    ></v-date-picker>
                </v-menu>
                <v-autocomplete 
                  v-model="formData.client" 
                  label="Client*" 
                  :items="clientOptions" 
                  item-text="name"
                  item-value="name" 
                  :rules="[clientRule]"
                  clearable
                >
                  <template v-slot:prepend-item>
                    <v-list>
                      <v-list-item v-for="(client) in clientOptionsFiltered" :key="client._id"
                        @click="selectClient(client)">
                        <v-list-item-title>{{ client.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-autocomplete>
                  
                <v-select label="Type de demande" v-model="formData.requestType" clearable :items="requestTypeOptions"></v-select>

              </div>
              <div style="width: 48%;">
                <v-text-field label="URLs demandé de la nouvelle base*" v-model="formData.requestedURLs" required
                :rules="[requestedURLsRule]"></v-text-field>
                <v-select label="Nature de la base" clearable v-model="formData.databaseNature"
                  :items="databaseNatureOptions" ></v-select> 
                <v-autocomplete label="Fuseau horaire*" 
                  v-model="formData.timezone"
                  :items="timezones"
                  :rules="[timezoneRule]"
                  clearable
                  ></v-autocomplete>
              </div>
            </div>
            <v-row>
              <v-col cols="5"> 
              <v-select label="TOPS" v-model="formData.tops" multiple clearable :items="topsOptions"></v-select>
              </v-col>
              <v-col cols="3">
                <v-checkbox v-if="formData.databaseNature === 'Globale'" label="Global Activation"
                  v-model="formData.globalActivation"></v-checkbox>
              </v-col>
              <v-col cols="2"> 
                <v-checkbox label="HTTPS" v-model="formData.isHTTPS"></v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-checkbox label="GTA" v-model="formData.gta"></v-checkbox>
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between"
            style="gap: 1rem;"
            >
              <v-select label="Usage" clearable v-model="formData.usage" :items="usageOptions"></v-select>
              <v-select label="Type de base*" v-model="formData.databaseType" clearable :items="databaseTypeOptions"
                :rules="[databaseTypeRule]" >
              </v-select>
            </div>
            <!-- remarques -->
            <v-textarea label="Remarques" rows="3" v-model="formData.remarks"></v-textarea>
          </v-card-text>
        </div>
        <v-divider vertical></v-divider>
        <!-- Information sur l'installation -->
        <div style="width: 48%;">
          <v-card-text>
            <v-checkbox label="URL SDP" v-model="formData.urlSdp"></v-checkbox>
            <v-text-field v-if="formData.urlSdp" label="Saisir l'URL SDP" v-model="formData.sdpUrl"></v-text-field>
            <v-checkbox label="URL SDC" v-model="formData.urlSdc"></v-checkbox>
            <v-text-field v-if="formData.urlSdc" label="Saisir l'URL SDC" v-model="formData.sdcUrl"></v-text-field>
          </v-card-text>
          <!-- Options -->
          <v-card-text>
            <!-- afficher model2 par defaut -->
            <v-autocomplete 
              label="Code source" 
              v-model="formData.sourceCode"
              :items="sourceDatabaseOptions" 
              item-text="name"
              clearable

              ></v-autocomplete>
            <template v-if="formData.sourceCode !== 'modele2.planett.net' && formData.sourceCode && formData.sourceCode.length > 0">
              <v-textarea label="Remarques Code Source" rows="3" v-model="formData.remarksCodeSource"></v-textarea>
            </template>
            <v-autocomplete 
              label="Base de données source" 
              v-model="formData.sourceDatabase"
              :items="sourceDatabaseOptions" 
              item-text="name" 
              item-value="name" 
              clearable
              ></v-autocomplete>
            <template v-if="sourceDatabaseOptionsFiltered.length > 0">
              <v-list>
                <v-list-item v-for="(db, index) in sourceDatabaseOptionsFiltered" :key="index"
                  @click="selectSourceDatabase(db)">
                  <v-list-item-title>{{ db.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </template>

            <div class="d-flex justify-space-between align-center mt-4 mb-4">
              <v-checkbox label="Toutes les données" v-model="allData" @change="toggleAllData"></v-checkbox>
              <v-checkbox label="Utilisateur" v-model="formData.copyUser" :disabled="allData"></v-checkbox>
              <v-checkbox label="Paramètres RH/HR & Absences" v-model="formData.copyHr" :disabled="allData"></v-checkbox>
              <v-checkbox label="SDP" v-model="formData.copySdp" :disabled="allData"></v-checkbox>
              <v-checkbox label="SDC/Customer" v-model="formData.copySdc" :disabled="allData"></v-checkbox>
              <v-checkbox label="Planning" v-model="formData.copyPlanning" :disabled="allData"></v-checkbox>
            </div>
            <!-- <v-file-input label="Logo*" @change="handleLogoChange" :value="logo" accept="image/*"
              type="file" :rules="[logoRule]"></v-file-input> -->
            <!-- expliquer que l ajoue de logo se fait via une manip  externe -->
            <v-text-field label="Logo (à ajouter via une manipulation externe)" disabled></v-text-field>
          </v-card-text>
          <!-- Mode de calcul -->
          <v-card-text>
            <div class="d-flex justify-space-between">
              <v-checkbox label="Espace de stockage" v-model="formData.storageSpace"></v-checkbox>
              <v-checkbox label="Signatures" v-model="formData.signatures"></v-checkbox>
              <v-checkbox label="Effectifs" v-model="formData.employees"></v-checkbox>
            </div>
          </v-card-text>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeModal">
          Annuler
        </v-btn>
        <v-btn color="success" variant="text" :disabled="!formValid" @click="closeCreateBasesModal">
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import BasesService from '@/Services/SupportVision/BasesService';
import Images from '@/Utils/Images';
import moment from 'moment';
import momentTimeZones from 'moment-timezone';
import { mapState } from 'vuex';
import { VAutocomplete } from 'vuetify/lib';

export default {
  name: 'basesCreate',
  components: {
    VAutocomplete,
  },
  data: () => ({
    dialogCreateBases: false,
    contactDialog: false,
    allData: false,
    formData: {
      contact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        function: '',
      },
      requestDate: '',
      baseName: '',
      client: null,
      requestType: null,
      requestedURLs: '',
      databaseNature: null,
      timezone: 'Europe/Paris',
      email: '',
      globalActivation: false,
      remarks: '',
      usage: null,
      databaseType: null,
      tops: [],
      gta: false,
      urlSdp: false,
      sdpUrl: '',
      urlSdc: false,
      sdcUrl: '',
      sourceDatabase: null,
      copyUser: false,
      copyHr: false,
      copySdp: false,
      copySdc: false,
      newLogoPreview: null,
      storageSpace: false,
      signatures: false,
      employees: false,
      isHTTPS: false,
      isActive: false,
      isValidateBase: false,
      validateBaseDate: '',
      isSysAdminValidate: false,
      isOpenedToOutside: false,
      sourceCode: "modele2.planett.net",
      remarksCodeSource: '',
      copyPlanning: false,
    },
    logo: null,
    fileName: null,
    clientOptions: [],
    requestTypeOptions: ['Création', 'Archivage'],
    databaseNatureOptions: ['Locale', 'Globale'],
    usageOptions: ['Usage Interne', 'Préproduction', 'Production'],
    databaseTypeOptions: [
      'Standard',
      'Interimaire',
      'Evénementiel',
      'Centre de formation',
    ],
    topsOptions:[
      'Profile',
      'Planning',
      'Dimensionnement'
    ],
    sourceDatabaseOptions: [],
    isFormValid: false,
    loader: false,
    clientSearch: '',
    clientOptionsFiltered: [],
    sourceDatabaseSearch: '',
    sourceDatabaseOptionsFiltered: [],
    requestDateRule: (v) => !!v || 'Champ requis',
    clientRule: (v) => !!v || 'Champ requis',
    requestedURLsRule: (v) => {
      if (!v) return 'Champ requis';
  
      // Vérifie si l'URL contient des caractères non autorisés
      if (!/^[a-zA-Z0-9-_.]+$/.test(v)) {
        return "L'URL ne peut contenir que des lettres, des chiffres, des tirets (-), des underscores (_) et des points (.)";
      }
      
      // Vérifie si l'URL commence ou se termine par un tiret ou un point
      if (/^[-.]|[-.]$/.test(v)) {
        return "L'URL ne peut pas commencer ou se terminer par un tiret (-) ou un point (.)";
      }
      
      // Vérifie si l'URL contient des termes interdits
      const forbiddenTerms = ['http', 'https', 'www'];
      for (let term of forbiddenTerms) {
        if (v.toLowerCase().includes(term)) {
          return `L'URL ne peut pas contenir le terme "${term}"`;
        }
      }
      
      // Vérifie le format général de l'URL (doit contenir au moins un point)
      if (!/^([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+$/.test(v)) {
        return "L'URL doit être au format valide (ex: example.com)";
      }
      
      return true;
    },
    baseNamesRule: (v) => {
      if (!v) return 'Champ requis';
  
      // Vérifie si l'URL contient des caractères non autorisés
      if (!/^[a-zA-Z0-9-_.]+$/.test(v)) {
        return "Le nom de la base ne peut contenir que des lettres, des chiffres, des tirets (-), des underscores (_) et des points (.)";
      }
      
      // Vérifie si l'URL commence ou se termine par un tiret ou un point
      if (/^[-.]|[-.]$/.test(v)) {
        return "Le nom de la base ne peut pas commencer ou se terminer par un tiret (-) ou un point (.)";
      }
      
      // Vérifie si l'URL contient des termes interdits
      const forbiddenTerms = ['http', 'https', 'www'];
      for (let term of forbiddenTerms) {
        if (v.toLowerCase().includes(term)) {
          return `Le nom de la base ne peut pas contenir le terme "${term}"`;
        }
      }
      
      // Vérifie le format général de l'URL (doit contenir au moins un point)
      if (!/^([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+$/.test(v)) {
        return "Le nom de la base doit être au format valide (ex: example.com)";
      }
      
      return true;
    },
    databaseTypeRule: (v) => !!v || 'Champ requis',
    logoRule: (v) => !!v || 'Champ requis',
    timezoneRule: (v) => !!v || 'Champ requis',
    modalKey: 0,
    timezones: [],
  }),
  computed: {
    ...mapState(['baseList', 'clientList', 'informations']),
    formValid() {
      return (
        this.requestedURLsRule(this.formData.requestedURLs) === true &&
        !!this.formData.requestDate &&
        !!this.formData.client &&
        !!this.formData.requestedURLs &&
        // !/\s/.test(this.formData.requestedURLs) &&
        !!this.formData.databaseType &&
        // !!this.logo &&
        !!this.formData.baseName &&
        !!this.formData.timezone
      );
    },
    currentMail() {
      return this.informations.emailAddress;
    },
    // retourne par defaut modele2.planett.net dans le select du tableau sourceDatabaseOptions
    sourceCodeDefault() {
      return this.sourceDatabaseOptions.filter((db) => db.name.toLowerCase().includes(this.sourceDatabaseSearch.toLowerCase()));
    },
  },
  filters: {
    formatDate(date) {
      if (!date) return null;
      return moment(date).format('DD/MM/YYYY');
    },
  },
  methods: {
    closeModal() {
      this.resetForm();
      this.dialogCreateBases = false;
      this.$emit('close-modal');
    },
    toggleAllData() {
      if (this.allData) {
        this.formData.copyUser = true;
        this.formData.copyHr = true;
        this.formData.copySdp = true;
        this.formData.copySdc = true;
        this.formData.copyPlanning = true;
      } else {
        this.formData.copyUser = false;
        this.formData.copyHr = false;
        this.formData.copySdp = false;
        this.formData.copySdc = false;
        this.formData.copyPlanning = false;
      }
    },
    async handleLogoChange(file) {
      if (file) {
        let b64 = await Images.toBase64(file);
        this.newLogoPreview = b64;
        this.logo = file;
      }
    }, 
    selectClient(client) {
      this.formData.client = client;
      this.clientSearch = '';
      this.clientOptionsFiltered = [];
    },
    selectSourceDatabase(db) {
      this.formData.sourceDatabase = db;
      this.sourceDatabaseSearch = '';
      this.sourceDatabaseOptionsFiltered = [];
    },
    addContact() {
      this.contactDialog = false;
    },
    async closeCreateBasesModal() {
      this.loader = true;
      // gestion de l'upload du logo
      let form = new FormData();
      // envoyer dans le logo l'id de la base et le nom de la base
      form.append('file', this.logo)
      // ajouter currentMail dans le formData
      this.formData.email = this.currentMail;

      if (this.formValid) {
        // si step1 2 3 4 5 6 n'existe pas alors on le crée et on le met à false
        if (!this.formData.requestDate) {
          this.formData.step1 = false;
        } else {
          this.formData.step1 = true;
        }
        if (!this.formData.step2) {
          this.formData.step2 = false;
        }
        if (!this.formData.step3) {
          this.formData.step3 = false;
        }
        if (!this.formData.step4) {
          this.formData.step4 = false;
        }
        if (!this.formData.step5) {
          this.formData.step5 = false;
        }
        const response = await BasesService.createBase(this.formData);
        if (response.message == 'Base déjà existante') {
          this.$nError('Base déjà existante');
          this.loader = false;
          return;
        }
        // // Mettre à jour le store
        const updatedBaseList = await BasesService.getBasesList();
        if (this.logo) {
          form.append('baseId', response._id);
          form.append('baseName', response.requestedURLs);
          // le nom du ficher pour eviter les doublons
          form.append('fileName', this.fileName);
          const res = await BasesService.createLogoBase(form); 
        }
        this.$store.commit('setBaseList', updatedBaseList);

        this.resetForm();
        this.closeModal();
        this.$emit('close-modal');
        this.$emit('client-created');
        this.loader = false;

        // notification
        this.$nInfo('Demande en attente de validation');
      }
    },
    resetForm() {
      this.formData = {
        contact: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          function: '',
        },
        requestDate: moment().format('YYYY-MM-DD'),
        baseName: '',
        remarks: '',
        client: null,
        requestType: null,
        requestedURLs: '',
        databaseNature: null,
        timezone: 'Europe/Paris',
        globalActivation: false,
        usage: null,
        databaseType: null,
        tops: false,
        gta: false,
        urlSdp: false,
        sdpUrl: '',
        urlSdc: false,
        sdcUrl: '',
        sourceDatabase: null,
        copyUser: false,
        copyHr: false,
        copySdp: false,
        copySdc: false,
        logo: null,
        storageSpace: false,
        signatures: false,
        employees: false,
        isHTTPS: false,
        isEvenementiel: false,
        isInterimaire: false,
        isActive: false,
        isSysAdminValidate: false,
        sourceCode: "modele2.planett.net",
        remarksCodeSource: '',
        copyPlanning: false,
      };
      this.logo = null;
      this.clientRule = (v) => !!v || 'Champ requis';
      this.requestedURLsRule = (v) => {
        if (!v) return 'Champ requis';
        if (/\s/.test(v)) return "L'URL ne peut pas contenir d'espaces";
        return true;
      };
      this.baseNamesRule = (v) => !!v || 'Champ requis';
      this.databaseTypeRule = (v) => !!v || 'Champ requis';
      this.logoRule = (v) => !!v || 'Champ requis';
      this.timezoneRule = (v) => !!v || 'Champ requis';
      this.modalKey += 1;
    },
  },
  async mounted() {
    // afficher la date du jour par défaut
    this.formData.requestDate = moment().format('YYYY-MM-DD');
    for (const client of this.clientList) {
      this.clientOptions.push({
        name: client.name,
        id: client._id,
      });
    }
    for (const base of this.baseList.data) {
      this.sourceDatabaseOptions.push({
        name: base.requestedURLs,
        id: base._id,
      });
    }
    this.timezones = momentTimeZones.tz.names();
  },
};
</script>
<style scoped>
.form-container {
  gap: 1rem;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
</style>
