<template>
  <div>
    <v-stepper>
      <v-stepper-header>
        <v-stepper-step 
          step="1" 
          :complete="baseSelected.step1 ? true : false"
          :rules="[() => baseSelected.step1]"
        >
          Demande de creation
          <small v-if="baseSelected.requestDate"
            class="grey--text text--darken-4" 
          >
            {{ baseSelected.requestDate | formatDate }}
          </small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step 
          step="2"
          :rules="[() => baseSelected.step2]" 
          :complete="baseSelected.step2 ? true : false"
          @click="openListBasesModal" style="cursor: pointer;"
        >
          Validation de la direction
          <small v-if="!baseSelected.step2">
            Veuillez valider la demande
          </small>
          <small v-else-if="baseSelected.validationDirectionDate" class="grey--text text--darken-4">
            {{ baseSelected.validationDirectionDate | formatDate }}
          </small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step 
          step="3"
          :complete="baseSelected.step3 ? true : false"
          :rules="[() => baseSelected.step3]"
          @click="openSysAdminTcheck" style="cursor: pointer;"
        >
          Validation du sysAdmin 
          <small v-if="!baseSelected.step3">
            Veuillez valider la demande
          </small>
          <small v-else-if="baseSelected.sysAdminValidationDate" class="grey--text text--darken-4">
            {{ baseSelected.sysAdminValidationDate | formatDate }}
          </small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
         step="4"
          :complete="baseSelected.step4 ? true : false"
          :rules="[() => baseSelected.step4]"
        >
          Test de la base
          <small v-if="!baseSelected.step4 && !baseSelected.test_client" >
            <!-- menu avec un checkbox -->
            <v-menu offset-y :disabled="!baseSelected.step3 || !isDemandeur">
              <template v-slot:activator="{ on, attrs }" >
                <p
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  class="mb-0"
                  @click="handleMenuClick"
                >
                  <!-- si failedTest afficher En attente de verification de vos test -->
                  {{ failedTest && baseSelected.isFailed ? 'En attente de vérification' : 'Validez test de la base' }}
                </p>
              </template>
              <v-card>
                <v-card-text  class="d-flex align-center flex-column">
                  <v-checkbox
                    class="mr-2"
                    v-model="baseSelected.step4"
                    label="Test Ok"
                    color="success"
                    @click="nextStep"
                  ></v-checkbox>
                
                  <v-checkbox
                    class="mr-2"
                    v-model="testKo"
                    label="Test KO"
                    color="error"
                  ></v-checkbox>  
                </v-card-text>
              </v-card>
            </v-menu>
          </small>
          <small v-else-if="baseSelected.step4" class="grey--text text--darken-4">
            {{ baseSelected.testedDate | formatDate }}
          </small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
         step="5"
          :complete="baseSelected.step5 ? true : false"
          :rules="[() => baseSelected.step5]"
          @click="openOutSide" style="cursor: pointer;"
        >
          Ouverture de la base
          <small v-if="!baseSelected.step5">
            Ouvrir la base
          </small>
          <small v-else-if="baseSelected.isOpenedToOutside" class="grey--text text--darken-4">
            {{ baseSelected.validateBaseDate | formatDate }}
          </small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="6"
          :complete="baseSelected.step5 ? true : false"
          :rules="[() => baseSelected.step5]"
          :color="baseSelected.step5 ? 'success' : 'error'"
        >
          {{ baseSelected.isOpenedToOutside ? 'Mail envoyé' : 'Mail de confirmation' }}
          <small v-if="baseSelected.step5">
            <span class="green--text">{{ baseSelected.validateBaseDate | formatDate }}</span>
          </small>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>

    <v-dialog v-model="testKo" width="500">
      <v-card>
        <v-card-title class="text-h5 primary white--text mb-8">
          Remarques sur le test KO
        </v-card-title>
        <v-card-text class="pb-0">
          <v-textarea
            label="Remarques" 
            clearable
            outlined
            auto-grow
            v-model="remarksTest"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="stopStep"
          >
            Envoyer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- modale update de bases -->
    <v-dialog v-model="dialogUpdate" max-width="90%">
      <basesUpdate />
    </v-dialog>

    <!-- modale de liste de bases en attente -->
    <v-dialog v-model="dialogListBases" max-width="90%">
      <basesListToTcheck @close-modal="closeListBasesModal" />
    </v-dialog>

    <!-- modal base a valider par sysadmin -->
    <v-dialog v-model="dialogSysAdminTcheck" max-width="90%">
      <sysAdminTcheck @close-modal="closeSysAdminTcheck" @validate-step4="nextStep"/>
    </v-dialog>
  </div>
</template>


<script>
import { mapMutations, mapState } from 'vuex';
import alertDeleteBase from './alertDeleteBase.vue';
import basesCreate from './basesCreate.vue';
import basesListToTcheck from './basesListToTcheck.vue';
import basesParametrage from './basesParametrage.vue';
import basesUpdate from './basesUpdate.vue';
import BasesService from '@/Services/SupportVision/BasesService';
import sysAdminTcheck from './sysAdminTcheck.vue';
import moment from 'moment';


export default {
  name: 'baseSteppers',
  components: {
    basesParametrage,
    basesCreate,
    basesUpdate,
    alertDeleteBase,
    basesListToTcheck,
    sysAdminTcheck,
  },
  data() {
    return {
      dialogParams: false,
      dialogCreate: false,
      dialogUpdate: false,
      alertDelete: false,
      dialogListBases: false,
      dialogSysAdminTcheck: false,
      testKo: false,
      remarksTest: '',
      failedTest: false,
    }
  }, 
  computed: {
    ...mapState(["baseList", "selectedBaseId", "informations","basesParams", "basesRights"]),
    ...mapMutations(["setBaseList","setBaseToTcheckId"]),
    baseSelected() {
      const selectedBase = this.baseList.data.find(base => base._id === this.selectedBaseId);
      return selectedBase || {};
    },
    isDirection() {
      return this.basesRights.direction
    },
    isSysAdmin() {
      return this.basesRights.sysAdmin
    },
    isDemandeur() {
      return this.baseSelected.email === this.informations.emailAddress;
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    }
  },
  methods: {
    openDialogParams() {
      this.dialogParams = true;
    },
    openListBasesModal() {
      this.$store.commit('setBaseToTcheckId', this.baseSelected._id);
      this.isDirection ? this.dialogListBases = true : this.$nError('Vous n\'êtes pas autorisé à valider la demande');
    },
    closeListBasesModal() {
      this.dialogListBases = false;
    },
    openSysAdminTcheck() {
      if (!this.baseSelected.step2) {
        this.$nError('L\'étape 2 n\'est pas encore validée. Vous ne pouvez pas procéder à la validation.');
      } else if (!this.isSysAdmin) {
        this.$nError('Vous n\'êtes pas autorisé à valider la demande.');
      } else {
        this.$store.commit('setBaseToTcheckId', this.baseSelected._id);
        this.dialogSysAdminTcheck = true;
      }
    },
    openOutSide() {
      if (!this.baseSelected.step4) {
        this.$nError('L\'étape 4 n\'est pas encore validée. Vous ne pouvez pas procéder à l\'ouverture de la base.');
      } else if (!this.isSysAdmin) {
        this.$nError('Vous n\'êtes pas autorisé à ouvrir la base.');
      } else {
        this.$store.commit('setBaseToTcheckId', this.baseSelected._id);
        this.dialogSysAdminTcheck = true;
      }
    },
    handleMenuClick() {
      if (!this.baseSelected.step3) {
        this.$nError('La base n\'a pas encore validée. Vous ne pouvez pas procéder au test de la base.');
      } else if (!this.isDemandeur) {
        this.$nError('Vous n\'êtes pas autorisé à effectuer le test de la base.');
      } 
    },
    closeSysAdminTcheck() {
      this.dialogSysAdminTcheck = false;
    },
    openDialogCreate() {
      this.dialogCreate = true;
    },
    openDialogUpdate() {
      this.dialogUpdate = true;
    },
    openAlertDelete() {
      this.alertDelete = true;
    },
    async refreshBasesList() {
      const bases = await BasesService.getBasesList();
      this.$store.commit('setBaseList', bases);
    },
    async nextStep() {
        // mettre à jour la base en bdd
        const dataKey = 'isTested';
        this.baseSelected.dataKey = dataKey;
        this.baseSelected.step4 = true;
        await BasesService.updateBase(this.baseSelected._id, {step: 'step4', dataKey: dataKey, baseId: this.baseSelected._id});
        await this.refreshBasesList();
      this.$emit('refresh-bases-list');
    },
    async stopStep() {
      // envoyer isTestFailed à la base en bdd 
      const dataKey = {
        isFailed: true,
        requestedURLs : this.baseSelected.requestedURLs,
        client : this.baseSelected.client,
        remarksTest: this.remarksTest,
      }
      this.failedTest = true;
      this.baseSelected.dataKey = dataKey;
      await BasesService.updateBase(this.baseSelected._id, {dataKey: dataKey, baseId: this.baseSelected._id});
      await this.refreshBasesList();
      this.$emit('refresh-bases-list');
      this.testKo = false;
    }
  },
  async mounted() { 
  },
}
</script>